/* orders.css */
.ResponsiveShiet {
    margin-top: 60px;
    margin-left: 100px;
    position: center;
    padding: 0%;
}

.Body_Content {
    padding-top: 60px;
    margin-top: -300px;
    margin-left: 150px;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3;
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px;
    color: #fff;
    transition: background-color 0.3s;
}

.wrapper button:hover {
    background-color: #0a6f9a;
}

.orders-card {
    background-color: #fff;
    border: 1px solid #dee2e6;
    margin: 20px;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.orders-card .card-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
    padding: 1rem;
}

.orders-card .card-body {
    padding: 0;
}

.orders-card .card-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
    padding: 1rem;
}

.table {
    margin-bottom: 0;
}

.table thead th {
    background-color: #f8f9fa;
    border-bottom: 2px solid #dee2e6;
}

.status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 0.25rem;
    font-size: 0.875rem;
}

.status-pending {
    background-color: #ffc107 !important;
    color: #000;
}

.status-completed {
    background-color: #28a745 !important;
    color: #fff;
}

.status-cancelled {
    background-color: #dc3545 !important;
    color: #fff;
}

.status-in-progress {
    background-color: #17a2b8 !important;
    color: #fff;
}

/* Para mantener compatibilidad con los estados en español */
.status-pendiente {
    background-color: #ffc107 !important;
    color: #000;
}

.status-completado {
    background-color: #28a745 !important;
    color: #fff;
}

.status-cancelado {
    background-color: #dc3545 !important;
    color: #fff;
}

.status-en-progreso {
    background-color: #17a2b8 !important;
    color: #fff;
}

.btn-group .btn {
    padding: 0.25rem 0.5rem;
}

.pagination-info {
    color: #6c757d;
    font-size: 0.875rem;
}

.modal-content {
    background-color: #fff;
    color: #212529;
}

.modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
}

.modal-footer {
    background-color: #f8f9fa;
    border-top: 1px solid #dee2e6;
}

.form-control, .form-select {
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #212529;
}

.form-control:focus, .form-select:focus {
    background-color: #fff;
    border-color: #86b7fe;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.form-control::placeholder {
    color: #6c757d;
}

/* Estilos para la sección de Status Trackers */
.status-overview-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
}

.status-tracker-card {
  height: 100%;
  transition: transform 0.2s;
}

.status-tracker-card:hover {
  transform: translateY(-5px);
}

/* Ajuste de espaciado para las cards */
.mb-4 {
  margin-bottom: 1.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

/* Estilos para el buscador */
.search-container {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.search-container .form-control,
.search-container .form-select {
  border-radius: 0.375rem;
}

.search-container .form-control:focus,
.search-container .form-select:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

/* Estilo para mensaje de no resultados */
.text-muted {
  color: #6c757d;
}

.py-4 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}