.container {
    max-width: 400px;
    margin: auto;
  }
  
  .card {
    padding: 20px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
  }
  
  .text-light {
    color: #ffffff;
  }
  
  .errmsg {
    color: red;
  }
  
  .btn {
    margin-top: 10px;
  }
  

  .wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }