.product-management-container {
    padding: 20px;
    background-color: #f4f4f4;
  }
  
  .product-management-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .product-management-header h1 {
    margin: 0;
    font-size: 24px;
    color: #333;
  }
  
  /* Improved action buttons styling */
  .action-buttons {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  
  .action-buttons .ant-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 80px;
    padding: 4px 12px;
  }
  
  /* Edit button specific styling */
  .action-buttons .ant-btn-primary.ant-btn-ghost {
    color: #1890ff;
    border-color: #1890ff;
  }
  
  .action-buttons .ant-btn-primary.ant-btn-ghost:hover {
    color: #40a9ff;
    border-color: #40a9ff;
  }
  
  /* Delete button specific styling */
  .action-buttons .ant-btn-danger.ant-btn-ghost {
    color: #ff4d4f;
    border-color: #ff4d4f;
  }
  
  .action-buttons .ant-btn-danger.ant-btn-ghost:hover {
    color: #ff7875;
    border-color: #ff7875;
  }
  
  /* Ensure icons are aligned properly */
  .action-buttons .ant-btn-icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .action-buttons {
      flex-direction: column;
      gap: 5px;
    }
  
    .action-buttons .ant-btn {
      width: 100%;
    }
  }

  .wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }