/* product.css */

.product-container {
    padding: 20px;
    background-color: #f9f9f9;
  }
  
  .form-container {
    max-width: 600px;
    margin: auto;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  @media (max-width: 768px) {
    .form-container {
      padding: 10px;
    }
  }