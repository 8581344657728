.user-management-container {
  min-height: 100vh;
  width: 100%;
  background-color: #f0f2f5;
}

.management-content-wrapper {
  margin-left: var(--sidebar-width);
  width: calc(100% - var(--sidebar-width));
  min-height: 100vh;
  padding: 1rem;
}

.management-header {
  position: relative;
  margin-bottom: 1.5rem;
  padding: 1rem;
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.management-header h2 {
  color: #1a1a1a;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  padding-right: 60px;
}

.management-header .navbar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1000;
  background: transparent;
  padding: 0.5rem;
}

.management-content {
  width: 100%;
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.search-section,
.table-section {
  background: white;
  border-radius: 8px;
  padding: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.user-details-card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.card-title {
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-size: 18px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.user-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item label {
  color: #8c8c8c;
  font-size: 14px;
}

.info-item span {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
}

.admin-row {
  background-color: #f6ffed;
}

.admin-row:hover {
  background-color: #d9f7be !important;
}

.actions-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}

.ant-modal-body .ant-form-item {
  margin-bottom: 16px;
}

.ant-modal-body .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-modal-footer {
  margin-top: 24px;
}

/* Estilos para los botones de acción en la tabla */
.action-buttons .ant-btn {
  padding: 4px 8px;
  height: 32px;
}

/* Estilos para el modal */
.ant-modal-content {
  border-radius: 8px;
}

.ant-modal-header {
  border-radius: 8px 8px 0 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .management-content-wrapper {
    margin-left: 0;
    width: 100%;
  }

  .search-section,
  .table-section,
  .user-details-card {
    padding: 16px;
    margin: 0 -16px;
    border-radius: 0;
    box-shadow: none;
  }

  .action-buttons {
    flex-direction: column;
  }

  .actions-section {
    margin-bottom: 12px;
  }

  .ant-modal {
    max-width: calc(100vw - 32px);
    margin: 16px;
  }
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.new-user-button {
  background-color: #128dc3;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  color: white;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.new-user-button:hover {
  background-color: #0a6f9a;
}

.actions-container {
  background: rgba(255, 255, 255, 0.9);
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.search-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.search-section {
  width: 100%;
}

.search-section .ant-input-search {
  width: 100%;
}

.buttons-group {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
  padding: 0.5rem 0;
}

.action-button {
  height: 40px;
  padding: 0 1.25rem;
  font-size: 0.875rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  border: none;
  color: white;
  font-weight: 500;
  transition: all 0.3s ease;
  flex: 0 1 200px;
  min-width: 150px;
  max-width: 200px;
}

.action-button:not(.ant-btn-dangerous) {
  background-color: #128dc3;
}

.action-button:not(.ant-btn-dangerous):hover {
  background-color: #0a6f9a;
}

.action-button.ant-btn-dangerous {
  background-color: #ff4d4f;
}

.action-button.ant-btn-dangerous:hover {
  background-color: #ff7875;
}

.action-button .anticon {
  font-size: 16px;
}

/* Ajuste responsive */
@media (max-width: 768px) {
  .actions-container {
    padding: 12px;
  }
  
  .buttons-group {
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px;
    margin: 1rem auto;
  }
  
  .action-button {
    width: 100%;
    max-width: 100%;
  }
}

/* Sección de tabla */
.table-section {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

.ant-table-wrapper {
  width: 100%;
}

/* Modal */
.ant-modal {
  max-width: 90vw;
  margin: 1rem auto;
}

.ant-modal-content {
  padding: 1rem;
}

.ant-form-item {
  margin-bottom: 1rem;
}

/* Responsive breakpoints */
@media screen and (max-width: 1200px) {
  .management-content {
    padding: 0 1rem;
  }
  
  .user-info-grid {
    grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  }
}

@media screen and (max-width: 992px) {
  .buttons-group {
    justify-content: center;
    gap: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .user-management-container {
    padding-top: 60px;
  }

  .management-content-wrapper {
    min-height: calc(100vh - 60px);
  }

  .management-header {
    padding-top: 3rem;
  }

  .management-header h2 {
    text-align: center;
    padding-right: 0;
  }

  .management-header,
  .actions-container,
  .table-section,
  .user-details-card {
    margin: 0.5rem 0;
    border-radius: 4px;
  }

  .buttons-group {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    max-width: none;
  }

  .ant-table {
    font-size: 0.875rem;
  }

  .user-info-grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 576px) {
  .management-content-wrapper {
    padding: 0.25rem;
  }

  .management-header,
  .actions-container,
  .table-section,
  .user-details-card {
    border-radius: 0;
  }

  .management-header h2 {
    font-size: 1.25rem;
  }

  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .action-buttons .ant-btn {
    width: 100%;
    margin: 0.25rem 0;
  }

  .ant-modal {
    max-width: 95vw;
    margin: 0.5rem;
  }
}

/* Ajustes para dispositivos muy pequeños */
@media screen and (max-width: 320px) {
  .management-content {
    padding: 0;
  }

  .actions-container,
  .table-section,
  .user-details-card {
    border-radius: 0;
  }
}

/* Ajustes de accesibilidad */
@media (prefers-reduced-motion: reduce) {
  .action-button,
  .ant-btn {
    transition: none;
  }
}
