:root {
    --bg-dark: #343a40; /* Color de fondo oscuro */
    --text-light: #ffffff; /* Color de texto claro */
    --text-secondary: #6c757d; /* Color de texto secundario */
    --hover-bg: rgba(255, 255, 255, 0.1);
    --dropdown-bg: #495057; /* Color de fondo del dropdown al pasar el mouse */
    --sidebar-width: 250px;
    --sidebar-bg: #343a40;
    --text-muted: #6c757d;
    --border-color: rgba(255, 255, 255, 0.1);
    height: 100%;
}

.custom-left {
    text-align: left;
}

.custom-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: var(--sidebar-width);
    height: 100vh;
    background: var(--sidebar-bg);
    color: var(--text-light);
    display: flex;
    flex-direction: column;
    z-index: 1000;
    transition: transform 0.3s ease;
}

.sidebar .flex-grow-1 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.sidebar-header {
    padding: 1rem;
    border-bottom: 1px solid var(--border-color);
}

.navbar-brand {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: var(--text-light);
    text-decoration: none;
    font-size: 1.25rem;
}

.navbar-brand:hover {
    color: var(--text-light);
}

.nav-menu {
    list-style: none;
    padding: 1rem 0;
    margin: 0;
    flex: 1;
}

.nav-item {
    margin: 0.25rem 0;
}

.nav-link {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: var(--text-light);
    text-decoration: none;
    transition: background-color 0.2s;
    cursor: pointer;
}

.nav-link:hover {
    background-color: var(--hover-bg);
    color: var(--text-light);
}

.nav-link i {
    margin-right: 0.75rem;
    font-size: 1.1rem;
}

.dropdown-menu {
    background-color: var(--sidebar-bg);
    border: 1px solid var(--border-color);
    margin-top: 0;
    border-radius: 0.25rem;
    padding: 0.5rem 0;
    min-width: 200px;
}

.dropdown-item {
    color: var(--text-light);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
}

.dropdown-item:hover {
    background-color: var(--hover-bg);
    color: var(--text-light);
}

.nav-profile {
    padding: 1rem;
    border-top: 1px solid var(--border-color);
    background-color: var(--sidebar-bg);
}

.nav-profile .dropdown {
    width: 100%;
}

.nav-profile .dropdown-toggle {
    width: 100%;
    background: transparent !important;
    border: none !important;
    padding: 0.5rem !important;
    color: var(--text-light) !important;
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
}

.nav-profile .dropdown-toggle::after {
    display: none !important;
}

.nav-profile .dropdown-toggle:hover,
.nav-profile .dropdown-toggle:focus,
.nav-profile .dropdown-toggle:active {
    background-color: var(--hover-bg) !important;
    box-shadow: none !important;
    outline: none !important;
}

.nav-profile .dropdown-menu {
    width: 100% !important;
    background-color: var(--sidebar-bg) !important;
    border: 1px solid var(--border-color) !important;
    margin-top: 0.5rem !important;
    border-radius: 4px !important;
    overflow: hidden !important;
}

.nav-profile .dropdown-item {
    color: var(--text-light) !important;
    padding: 0.75rem 1rem !important;
    display: flex !important;
    align-items: center !important;
    gap: 0.5rem !important;
    transition: background-color 0.2s !important;
}

.nav-profile .dropdown-item:hover,
.nav-profile .dropdown-item:focus {
    background-color: var(--hover-bg) !important;
    color: var(--text-light) !important;
}

.nav-profile img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

/* Ajustes para móvil */
@media (max-width: 768px) {
    .nav-profile .dropdown-menu {
        position: static !important;
        float: none !important;
        width: 100% !important;
        margin: 0.5rem 0 0 0 !important;
    }
}

/* Ajuste para el contenido principal */
.main-content {
    margin-left: var(--sidebar-width);
    min-height: 100vh;
    padding: 2rem;
    transition: margin-left 0.3s;
}

/* Ajustes responsive */
@media (max-width: 576px) {
    .sidebar {
        width: 100%;
        max-width: 300px;
    }

    .nav-link {
        padding: 0.6rem 0.8rem !important;
    }
}

/* Asegurar que los íconos se muestren correctamente */
.bi {
    font-size: 1.2rem;
    margin-right: 0.5rem;
}

/* Ajustar el espaciado de los elementos del menú */
.nav-pills .nav-item {
    margin-bottom: 0.5rem;
}

.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100vh;
    background-color: #343a40;
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.navbar-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.navbar-header {
    padding: 1rem 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.navbar-brand i {
    font-size: 1.5rem;
}

.navbar-menu {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    gap: 0.5rem;
}

.nav-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    color: white;
    text-decoration: none;
    border-radius: 4px;
    gap: 0.75rem;
    transition: background-color 0.2s;
}

.nav-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}

.nav-item i {
    font-size: 1.25rem;
}

.navbar-footer {
    margin-top: auto;
    padding-top: 1rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

@media (max-width: 768px) {
    .navbar-container {
        transform: translateX(-100%);
        transition: transform 0.3s;
    }

    .navbar-container.show {
        transform: translateX(0);
    }

    .management-content-wrapper {
        margin-left: 0;
        width: 100%;
    }
}