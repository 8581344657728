/* perfil.css */
.container {
    opacity: 75%;
}
.profile-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9f9f9; /* Fondo suave */
    padding: 30px;
    border-radius: 15px; /* Bordes más redondeados */
    box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1); /* Sombra más pronunciada */
    max-width: 450px; /* Ancho máximo */
    margin: 30px auto; /* Centrar el componente */
    transition: transform 0.3s; /* Transición suave para el hover */

}

.profile-container:hover {
    transform: scale(1.02); /* Efecto de elevación al pasar el ratón */
}

.profile-avatar {
    width: 120px; /* Tamaño del avatar */
    height: 120px; /* Tamaño del avatar */
    border-radius: 50%; /* Hacer el avatar circular */
    object-fit: cover; /* Asegurarse de que la imagen se ajuste bien */
    margin-bottom: 20px; /* Espacio debajo del avatar */
    border: 3px solid #007bff; /* Borde azul alrededor del avatar */
    transition: border-color 0.3s; /* Transición suave para el borde */
}

.profile-avatar:hover {
    border-color: #0056b3; /* Color más oscuro al pasar el ratón */
}

.profile-name {
    font-size: 2rem; /* Tamaño de fuente más grande para el nombre */
    font-weight: bold; /* Negrita para el nombre */
    color: #333; /* Color oscuro para el texto */
    margin: 0; /* Eliminar márgenes por defecto */
    text-align: center; /* Centrar el texto */
}

.profile-bio {
    font-size: 1.1rem; /* Tamaño de fuente para la biografía */
    color: #666; /* Color gris para el texto */
    text-align: center; /* Centrar el texto */
    margin-top: 15px; /* Espacio encima de la biografía */
}

.profile-buttons {
    display: flex; /* Usar flexbox para los botones */
    justify-content: center; /* Centrar los botones */
    margin-top: 25px; /* Espacio encima de los botones */
}

.profile-button {
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto del botón */
    border: none; /* Sin borde */
    border-radius: 5px; /* Bordes redondeados */
    padding: 12px 18px; /* Espaciado interno */
    margin: 0 8px; /* Espacio entre botones */
    cursor: pointer; /* Cambiar el cursor al pasar el ratón */
    transition: background-color 0.3s, transform 0.2s; /* Transición suave */
}

.profile-button:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el ratón */
    transform: translateY(-2px); /* Efecto de elevación al pasar el ratón */
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
    .profile-container {
        padding: 20px; /* Reducir el padding en pantallas pequeñas */
        max-width: 90%; /* Aumentar el ancho máximo en pantallas pequeñas */
    }

    .profile-name {
        font-size: 1.5rem; /* Ajustar el tamaño de fuente en pantallas pequeñas */
    }

    .profile-bio {
        font-size: 1rem; /* Ajustar el tamaño de fuente en pantallas pequeñas */
    }

    .profile-button {
        padding: 10px 15px; /* Reducir el padding de los botones */
    }
}

.wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }

.profile-card {
  background-color: #fff;
  border: 1px solid #dee2e6;
  margin: 20px 0;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.profile-card .card-header {
  background-color: #f8f9fa;
  border-bottom: 1px solid #dee2e6;
  padding: 1rem;
}

.password-section {
  border-top: 1px solid #dee2e6;
  padding-top: 1.5rem;
  margin-top: 1.5rem;
}

.password-section h4 {
  color: #495057;
  margin-bottom: 0;
}

.form-control:focus {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.alert {
  margin-bottom: 1rem;
}