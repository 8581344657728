/* customers.css */

body {
    background-color: #f8f9fa; /* Color de fondo suave */
}

.ResponsiveShiet {
    margin: 20px; /* Espaciado alrededor del contenedor */
}

.Body_Content {
    background-color: #ffffff; /* Fondo blanco para el contenido */
    border-radius: 8px; /* Bordes redondeados */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra suave */
    padding: 20px; /* Espaciado interno */
}

.Tittle {
    margin-bottom: 20px; /* Espaciado inferior */
}

h2 {
    color: #343a40; /* Color del título */
}

.NavBar {
    margin-bottom: 20px; /* Espaciado inferior */
}

.add-customer-form {
    display: flex; /* Usar flexbox para el formulario */
    flex-wrap: wrap; /* Permitir que los elementos se envuelvan */
    gap: 10px; /* Espacio entre los campos */
    margin-bottom: 20px; /* Espaciado inferior */
}

.add-customer-form input {
    flex: 1; /* Hacer que los inputs ocupen el espacio disponible */
    min-width: 200px; /* Ancho mínimo para los inputs */
    padding: 10px; /* Espaciado interno */
    border: 1px solid #ced4da; /* Borde gris claro */
    border-radius: 4px; /* Bordes redondeados */
    font-size: 16px; /* Tamaño de fuente */
}

.add-customer-form button {
    padding: 10px 15px; /* Espaciado interno */
    background-color: #007bff; /* Color de fondo del botón */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cambiar cursor al pasar el mouse */
    font-size: 16px; /* Tamaño de fuente */
    transition: background-color 0.3s; /* Transición suave */
}

.add-customer-form button:hover {
    background-color: #0056b3; /* Color más oscuro al pasar el mouse */
}

.CustomerList {
    margin-top: 20px; /* Espaciado superior */
}

table {
    width: 100%; /* Ancho completo */
    border-collapse: collapse; /* Colapsar bordes */
}

th, td {
    padding: 12px; /* Espaciado interno */
    text-align: left; /* Alinear texto a la izquierda */
    border-bottom: 1px solid #dee2e6; /* Borde inferior */
}

th {
    background-color: #f1f1f1; /* Color de fondo para encabezados */
    color: #495057; /* Color del texto */
}

tr:hover {
    background-color: #f8f9fa; /* Color de fondo al pasar el mouse sobre la fila */
}

button {
    padding: 5px 10px; /* Espaciado interno */
    background-color: #dc3545; /* Color de fondo del botón de eliminar */
    color: white; /* Color del texto */
    border: none; /* Sin borde */
    border-radius: 4px; /* Bordes redondeados */
    cursor: pointer; /* Cambiar cursor al pasar el mouse */
    transition: background-color 0.3s; /* Transición suave */
}

button:hover {
    background-color: #c82333; /* Color más oscuro al pasar el mouse */
}

/* Media Queries para Responsividad */
@media (max-width: 768px) {
    .add-customer-form {
        flex-direction: column; /* Cambiar a columna en pantallas pequeñas */
    }

    .add-customer-form input,
    .add-customer-form button {
        width: 100%; /* Hacer que los inputs y botones ocupen el ancho completo */
    }

    .CustomerList {
        margin-top: 10px; /* Reducir el margen superior en pantallas pequeñas */
    }
}

.wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }

.search-section {
  margin-bottom: 24px;
  padding: 16px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.ant-table-wrapper {
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.ant-table-cell {
  white-space: nowrap;
}

.ant-table-cell.description {
  white-space: normal;
  max-width: 300px;
}

@media (max-width: 768px) {
  .search-section {
    margin: 0 -16px 16px;
    border-radius: 0;
    box-shadow: none;
  }

  .ant-table-wrapper {
    margin: 0 -16px;
    border-radius: 0;
    box-shadow: none;
  }
}

.search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.search-header .ant-input-search {
  flex: 1;
}

.modal-footer {
  margin-top: 24px;
  text-align: right;
}

.ant-modal-body .ant-form-item:last-child {
  margin-bottom: 0;
}

.ant-modal-body textarea {
  resize: vertical;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .search-header {
    flex-direction: column;
  }

  .search-header .ant-btn {
    width: 100%;
  }
}