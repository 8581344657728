.nav-profile {
    width: 100%;
    display: flex;
    justify-content: center;
}

.profile-button {
    background: transparent;
    border: none;
    padding: 8px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.profile-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.profile-image {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.dropdown {
    width: auto;
    display: flex;
    justify-content: center;
}

.dropdown-menu {
    min-width: 150px;
    background-color: #343a40;
    border: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 0.5rem;
    transform: translateX(-50%);
    left: 50% !important;
    right: auto !important;
}

.dropdown-item {
    color: white;
    padding: 8px 16px;
    text-decoration: none;
    text-align: center;
}

.dropdown-item:hover {
    background-color: rgba(255, 255, 255, 0.1);
    color: white;
}
