
/* Espaciado principal del contenedor */
.p-6 {
    padding: 1.5rem;
  }
  
  /* Títulos principales */
  .text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }
  .font-bold {
    font-weight: 700;
  }
  .mb-6 {
    margin-bottom: 1.5rem;
  }
  
  /* Diseño de cuadrícula */
  .grid {
    display: grid;
  }
  .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    @media (min-width: 768px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  .lg\:grid-cols-4 {
    @media (min-width: 1024px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
  .gap-6 {
    gap: 1.5rem;
  }
  
  /* Tarjetas */
  .bg-white {
    background-color: #ffffff;
  }
  .rounded-lg {
    border-radius: 0.5rem;
  }
  .shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  .p-6 {
    padding: 1.5rem;
  }
  
  /* Títulos secundarios */
  .text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }
  .font-semibold {
    font-weight: 600;
  }
  .mb-4 {
    margin-bottom: 1rem;
  }
  
  /* Margen adicional en la sección inferior */
  .mt-8 {
    margin-top: 2rem;
  }
  .lg\:grid-cols-2 {
    @media (min-width: 1024px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
  }
  

  .wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }

  .ResponsiveShiet12 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    height: 100vh;
    padding: 20px;
  }
  
  .Body_Content12 {
    grid-column: 1;
    grid-row: 1;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 20px;
  }
  
  /* Media queries para hacer responsivo */
  @media (min-width: 768px) {
    .ResponsiveShiet12 {
      grid-template-columns: 1fr 3fr;
    }
    .Body_Content12 {
      grid-column: 2;
    }
  }
  
  @media (min-width: 1200px) {
    .ResponsiveShiet12 {
      grid-template-columns: 1fr 4fr;
    }
    .Body_Content12 {
      grid-column: 2;
    }
  }

  .Tittle12 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Opcional: para hacer el título más compacto */
  .Tittle12 h2 {
    margin-top: 3%;
    margin-bottom: 2%;
    color: white;
  }