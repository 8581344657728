.user-search-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 0 20px;
}

.search-header {
  margin-bottom: 2rem;
}

.search-header h2 {
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-size: 24px;
}

.search-box {
  display: flex;
  gap: 12px;
  align-items: center;
}

.search-input {
  height: 40px;
  border-radius: 6px;
}

.search-input:hover {
  border-color: #40a9ff;
}

.search-icon {
  color: #8c8c8c;
}

.search-button {
  height: 40px;
  padding: 0 24px;
  border-radius: 6px;
  font-weight: 500;
}

.user-card {
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

.card-title {
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  font-size: 18px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.user-info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
}

.info-item {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.info-item label {
  color: #8c8c8c;
  font-size: 14px;
}

.info-item span {
  color: #1a1a1a;
  font-size: 16px;
  font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .search-box {
    flex-direction: column;
  }
  
  .search-button {
    width: 100%;
  }
  
  .user-info-grid {
    grid-template-columns: 1fr;
  }
}

/* Hover effects */
.user-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
  transition: box-shadow 0.3s ease;
}

.info-item:hover label {
  color: #40a9ff;
  transition: color 0.3s ease;
}

.users-table {
  margin-bottom: 2rem;
  background: #fff;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
}

/* Estilo para filas de administradores */
.admin-row {
  background-color: #f6ffed;
}

.admin-row:hover {
  background-color: #d9f7be !important;
}

/* Ajustes para la tabla */
.ant-table-thead > tr > th {
  background: #fafafa;
  font-weight: 600;
}

.ant-table-tbody > tr:hover > td {
  background: #e6f7ff;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .user-search-container {
    padding: 0 10px;
  }
  
  .search-header h2 {
    font-size: 20px;
  }
  
  .users-table {
    margin: 1rem -10px;
    padding: 0.5rem;
    border-radius: 0;
    box-shadow: none;
  }
} 