.Body_user_Config {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.Tittle_user_Config {
  margin-bottom: 20px;
}

.NavBar {
  width: 100%;
  margin-bottom: 20px;
}

.config-container {
  width: 100%;
  max-width: 600px; /* Limita el ancho máximo del formulario */
  margin: 0 auto; /* Centra el contenedor */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-image-preview {
  margin-top: 20px;
  text-align: center;
}

@media (max-width: 768px) {
  .config-container {
    padding: 10px; /* Reduce el padding en pantallas pequeñas */
  }

  .profile-image-preview img {
    width: 80px; /* Ajusta el tamaño de la imagen en pantallas pequeñas */
    height: 80px;
  }
}

.wrapper button {
  width: 100%;
  height: 45px;
  background-color: #128dc3; /* Color de fondo del botón */
  outline: none;
  border-radius: 40px;
  box-shadow: 0 0 10px rgba(0,0,0, .1);
  cursor: pointer;
  font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
  color: #fff; /* Color del texto */
  transition: background-color 0.3s; /* Transición suave para el cambio de color */
}

.wrapper button:hover {
  background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
}