.BigHeader{/* Icoono de usuario */
    display: flex;
    flex-direction: row; /* Esto alinea los elementos  */
    gap: 20px; /* Añade un espacio entre los elementos, ajusta el valor según sea necesario*/
    color: aliceblue;
}
.Header1{
    display: flex;
    flex-direction: row; /* Esto alinea los elementos  */
    
}
.search-box{/*  css para el div del search box */
    background: transparent;
    padding: 8px 10px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    color: aliceblue;
}
.search-box input{/*  css para el input del search box */
    background: transparent;
    border: none;
    outline: none;
    padding: 5px;
    color: aliceblue;
}


.search-box icon{/*   css para el icono del search box */
    font-size: 22px;
    cursor: pointer;  
    color: aliceblue;
}
.notify{/* css para el cuadro de notificaciones  */
    background-color: transparent ;
    padding:12px;
    border-radius:10px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 40px !important;
    color: aliceblue;

}
.icon:hover{/*  css para los iconos en general   */
    transform: scale(1.3);
    cursor:pointer;
    transition: 0.5s ease-in-out;
}

.wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }