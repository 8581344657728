.product-order {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .product-order h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .product-order table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .product-order th, .product-order td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  
  .product-order th {
    background-color: #f0f0f0;
  }
  
  .product-order td {
    background-color: #fff;
  }
  
  .product-order a {
    text-decoration: none;
    color: #337ab7;
  }
  
  .product-order a:hover {
    color: #23527c;
  }