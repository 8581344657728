.sales-report-container {
    padding: 20px;
    background-color: #f4f4f4;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .sales-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .sales-table th, 
  .sales-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .sales-table thead {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .sales-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .sales-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  /* Revenue-specific styling */
  .sales-table .revenue-cell {
    font-weight: bold;
    color: #28a745; /* Green color for positive revenue */
    text-align: right;
  }
  
  .sales-table .revenue-cell.negative {
    color: #dc3545; /* Red color for negative revenue */
  }
  
  .sales-summary {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    margin-bottom: 20px;
  }
  
  .summary-metrics {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .metric {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .metric span {
    color: #666;
    margin-bottom: 10px;
    font-size: 0.9em;
    text-transform: uppercase;
  }
  
  .metric strong {
    font-size: 1.5em;
    color: #333;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .summary-metrics {
      flex-direction: column;
    }
  
    .sales-table {
      font-size: 0.9em;
    }
  }


  .wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }