/* DashboardCard.css */

.bg-white {
    background-color: white;
}

.rounded-lg {
    border-radius: 0.5rem; /* Ajusta el valor según sea necesario */
}

.shadow-md {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra media */
}

.p-6 {
    padding: 1.5rem; /* Espaciado interno */
}

.flex {
    display: flex;
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.text-gray-500 {
    color: #6b7280; /* Color gris */
}

.text-sm {
    font-size: 0.875rem; /* Tamaño de fuente pequeño */
}

.text-2xl {
    font-size: 1.5rem; /* Tamaño de fuente 2xl */
}

.font-bold {
    font-weight: bold;
}

.mt-1 {
    margin-top: 0.25rem; /* Margen superior */
}

.mt-2 {
    margin-top: 0.5rem; /* Margen superior */
}

.text-green-500 {
    color: #10b981; /* Color verde */
}

.text-red-500 {
    color: #ef4444; /* Color rojo */
}

.w-8 {
    width: 2rem; /* Ancho */
}

.h-8 {
    height: 2rem; /* Alto */
}

.text-blue-500 {
    color: #3b82f6; /* Color azul */
}

.wrapper button {
    width: 100%;
    height: 45px;
    background-color: #128dc3; /* Color de fondo del botón */
    outline: none;
    border-radius: 40px;
    box-shadow: 0 0 10px rgba(0,0,0, .1);
    cursor: pointer;
    font-size: 16px; /* Asegúrate de que el tamaño de fuente sea adecuado */
    color: #fff; /* Color del texto */
    transition: background-color 0.3s; /* Transición suave para el cambio de color */
  }
  
  .wrapper button:hover {
    background-color: #0a6f9a; /* Color de fondo al pasar el mouse */
  }