.inventory-report-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .inventory-summary {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .summary-metrics {
    display: flex;
    justify-content: space-around;
    gap: 20px;
  }
  
  .metric {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    padding: 15px;
    background-color: #f8f9fa;
    border-radius: 6px;
  }
  
  .metric span {
    color: #666;
    margin-bottom: 10px;
    font-size: 0.9em;
    text-transform: uppercase;
  }
  
  .metric strong {
    font-size: 1.5em;
    color: #333;
  }
  
  .inventory-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .inventory-table th, .inventory-table td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
  }
  
  .inventory-table thead {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .inventory-table tbody tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .inventory-table tbody tr:hover {
    background-color: #f5f5f5;
  }
  
  .download-excel-button {
    background-color: #128dc3;
    color: white;
    border: none;
    border-radius: 40px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .download-excel-button:hover {
    background-color: #0a6f9a;
  }