/* OrderStatusTracker.css */

/* Animación para el badge de estado */
@keyframes statusPulse {
    0% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2); }
    70% { box-shadow: 0 0 0 10px rgba(0, 0, 0, 0); }
    100% { box-shadow: 0 0 0 0 rgba(0, 0, 0, 0); }
  }
  
  /* Estilos principales */
  .order-status-card {
    max-width: 500px;
    margin: 2rem auto;
    transition: transform 0.3s ease;
    border-radius: 15px !important;
    border: none;
  }
  
  .order-status-card:hover {
    transform: translateY(-5px);
  }
  
  .status-badge {
    letter-spacing: 1px;
    animation: statusPulse 2s infinite;
    border-radius: 20px;
    font-weight: 400;
    min-width: 150px;
    display: inline-block;
  }
  
  /* Estados de color personalizados */
  .status-badge.pending {
    background-color: #6c757d !important;
    border: 2px solid #5a6268;
  }
  
  .status-badge.in-progress {
    background-color: #0d6efd !important;
    border: 2px solid #0b5ed7;
  }
  
  .status-badge.completed {
    background-color: #198754 !important;
    border: 2px solid #157347;
  }
  
  .status-badge.canceled {
    background-color: #dc3545 !important;
    border: 2px solid #bb2d3b;
  }
  
  /* Botones de estado */
  .status-button {
    transition: all 0.3s ease !important;
    position: relative;
    overflow: hidden;
    font-weight: 500;
    letter-spacing: 0.5px;
    border-radius: 8px !important;
    border: 2px solid transparent !important;
  }
  
  .status-button:hover:not(:disabled) {
    transform: scale(1.05);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  }
  
  .status-button:disabled {
    cursor: not-allowed;
    opacity: 0.9 !important;
    transform: scale(0.95);
  }
  
  /* Efecto de onda al hacer clic */
  .status-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 5px;
    height: 5px;
    background: rgba(255, 255, 255, 0.5);
    opacity: 0;
    border-radius: 50%;
    transform: translate(-50%, -50%);
  }
  
  .status-button:active::after {
    animation: buttonClickEffect 0.4s ease-out;
  }
  
  @keyframes buttonClickEffect {
    0% {
      width: 5px;
      height: 5px;
      opacity: 1;
    }
    100% {
      width: 200px;
      height: 200px;
      opacity: 0;
    }
  }
  
  /* Responsive Design */
  @media (max-width: 576px) {
    .order-status-card {
      margin: 1rem;
      border-radius: 12px !important;
    }
    
    .status-badge {
      font-size: 1rem !important;
      min-width: 120px;
      padding: 0.5rem 1rem !important;
    }
    
    .status-button {
      padding: 0.5rem !important;
      font-size: 0.9rem;
    }
  }

.status-tracker-card {
  min-width: 150px;
  max-width: 200px;
}

.status-badge-sm {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}

.status-button {
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem !important;
  margin-bottom: 2px !important;
}

.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  border-radius: 0.2rem;
}

.card-body.p-2 {
  padding: 0.5rem !important;
}

.text-muted {
  font-size: 0.75rem;
}